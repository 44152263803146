import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
// import BasePortableText from "@sanity/block-content-to-react"
// import clientConfig from "../../client-config"
// import serializers from "./serializers"
// import { buildImageObj } from "../lib/helpers"
// import { imageUrlFor } from "../lib/image-url"
import { Box, Heading, Text } from "../ziibo"
import { format } from "date-fns"
import Img from "gatsby-image"

const StyledHeroPost = styled(Box)`
	--base: 450px;
	--multiple: 1.4;
	position: relative;
	/* width: calc(var(--base) * var(--multiple)); */
	/* height: var(--base); */
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin: 0 auto 24px auto;
	max-width: 80vw;
	max-height: 70vh;
	z-index: 1;

	&::after {
		content: "";
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: 2;
		background-color: none;
		transition: all 0.5s ease;
	}
	&:hover {
		&::after {
			background-color: ${(props) => `${props.theme.colors["secondary"]["default"]}50`};
		}
	}

	& img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		/* border-radius: 8px; */
	}

	& .content-container {
		position: relative;
		/* border-radius: 8px 8px 0 0; */
		z-index: 3;
		width: 100%;
		background-color: ${(props) => `${props.theme.colors["primary"]["default"]}99`};
		backdrop-filter: blur(15px);
		margin: 0 auto;
		text-align: center;
		padding: 24px 0;
	}
`
const HeroPost = ({ post }) => {
	return (
		<Link to={`/blog/${post.slug.current}`}>
			<StyledHeroPost key={post.title} elevation={3}>
				<Img fluid={post.mainImage.asset.fluid} alt={post.mainImage.alt} style={{ position: "initial" }} imgStyle={{ maxWidth: "none" }} />
				<div className="content-container">
					<Heading as="h1" fontSize={500} color="primary" shade="light">
						{post.title}
					</Heading>
					<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						{post.authors.map((author) => {
							return (
								<Text fontSize={200} key={author._key} shade="light">
									{author.author.name}
								</Text>
							)
						})}
						{post.publishedAt && (
							<>
								<Text ml={1.2} mr={1.2} shade="light">
									|
								</Text>
								<Text fontSize={200} shade="light">
									{format(new Date(post.publishedAt), "MMMM do, yyyy")}
								</Text>
							</>
						)}
					</div>
					{/* <BasePortableText blocks={post._rawExcerpt} serializers={serializers} {...clientConfig.sanity} /> */}
				</div>
			</StyledHeroPost>
		</Link>
	)
}

export { HeroPost }
