import * as React from "react"
import { graphql } from "gatsby"
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, filterOutDocsPublishedInTheFuture } from "../lib/helpers"

import Layout from "../components/layout"
import { HeroPost } from "../components/hero-post"
import { PostPreviews } from "../components/post-previews"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
	const postNodes = (data || {}).posts
		? mapEdgesToNodes(data.posts).filter(filterOutDocsWithoutSlugs).filter(filterOutDocsPublishedInTheFuture)
		: []

	const featuredPosts = postNodes.slice(1)

	return (
		<Layout>
			<SEO title="Home" />
			<main style={{ margin: "24px auto", width: "80%" }}>
				<div>
					{/* {postNodes.map((node) => {
						return <PostPreview post={node} />
					})} */}
					<HeroPost post={postNodes[0]} />
				</div>
				<PostPreviews posts={featuredPosts} />
			</main>
		</Layout>
	)
}

export default IndexPage

export const query = graphql`
	# fragment SanityImage on SanityMainImage {
	# 	crop {
	# 		_key
	# 		_type
	# 		top
	# 		bottom
	# 		left
	# 		right
	# 	}
	# 	hotspot {
	# 		_key
	# 		_type
	# 		x
	# 		y
	# 		height
	# 		width
	# 	}
	# 	asset {
	# 		_id
	# 	}
	# }

	query IndexPageQuery {
		posts: allSanityPost(
			sort: { fields: [publishedAt], order: DESC }
			filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
		) {
			edges {
				node {
					id
					publishedAt
					mainImage {
						# ...SanityImage
						asset {
							fluid {
								...GatsbySanityImageFluid
							}
						}
						alt
					}
					title
					_rawExcerpt
					slug {
						current
					}
					authors {
						_key
						author {
							name
						}
					}
				}
			}
		}
	}
`
